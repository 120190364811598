// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktuelno-js": () => import("./../../src/pages/aktuelno.js" /* webpackChunkName: "component---src-pages-aktuelno-js" */),
  "component---src-pages-cart-js": () => import("./../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-cetverodjelne-slike-js": () => import("./../../src/pages/cetverodjelne-slike.js" /* webpackChunkName: "component---src-pages-cetverodjelne-slike-js" */),
  "component---src-pages-dvodjelne-slike-js": () => import("./../../src/pages/dvodjelne-slike.js" /* webpackChunkName: "component---src-pages-dvodjelne-slike-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jednodjelne-slike-js": () => import("./../../src/pages/jednodjelne-slike.js" /* webpackChunkName: "component---src-pages-jednodjelne-slike-js" */),
  "component---src-pages-petodjelne-slike-js": () => import("./../../src/pages/petodjelne-slike.js" /* webpackChunkName: "component---src-pages-petodjelne-slike-js" */),
  "component---src-pages-tapete-js": () => import("./../../src/pages/tapete.js" /* webpackChunkName: "component---src-pages-tapete-js" */),
  "component---src-pages-trodjelne-slike-js": () => import("./../../src/pages/trodjelne-slike.js" /* webpackChunkName: "component---src-pages-trodjelne-slike-js" */),
  "component---src-templates-product-detail-js": () => import("./../../src/templates/productDetail.js" /* webpackChunkName: "component---src-templates-product-detail-js" */)
}

